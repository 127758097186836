/*  Boot mixpanel for Sleepycat, from https://mixpanel.com/report/1954265/setup/ */

import mixpanel from 'mixpanel-browser';

// Send mixpanel events to the right MixPanel project
mixpanel.init(
	process.env.NODE_ENV === 'production' ? 
		"8b79d47129ee77b1f9116d08e70a7b32" : // Release/Production:   https://mixpanel.com/report/1954287
		"6f11c5edf91dd7bd2e7f25f92a199b52"   // Development/Testing:  https://mixpanel.com/report/1954265
);

export { mixpanel };