import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import * as Sentry from '@sentry/browser';

window.Sentry = Sentry;
if(process.env.NODE_ENV === 'production') {
	Sentry.init({ dsn: 'https://0b2f820763c04905ba9a410c1e7fa1fb@sentry.io/1401447' });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if(!window._pgStarted)
	serviceWorker.register();
